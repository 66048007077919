body {
  background-image: url('./images/Lighthouse.jpg');
  background-size: cover;
  display: grid;
  height: 100vh;
  margin: 0;
  place-items: center center;
}

.avatar-card {
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 2em 4em;
  box-shadow: none;
  opacity: 0.85;

  img {
    margin: 1em 0;
  }
  span {
    display: flex;
    width: 100%;
    margin-top: 0.75em;
    font-size: 1.75em;
    justify-content: space-between;

    a:visited {
      color: black;
    }

    a:hover {
      color: #724d56;
    }
  }
}

.emailBanner {
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  opacity: 0;

  &.show-banner {
    opacity: 1;
  }
  &.hide-banner {
    opacity: 0;
  }
  .calloutBanner {
    border-radius: 5px;
    position: relative;
    position: absolute;
    width: 250px;
    left: 0;
    right: 0;
    margin: 0 auto;
    margin-top: 2em;

    .body-text {
      text-align: center !important;
      display: flex;
      justify-content: center;

      svg {
        padding-right: 0.25em;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .desktop-link {
    display: none;
  }
  .mobile-link {
    color: black;
  }
}

@media only screen and (min-width: 601px) {
  .mobile-link {
    display: none;
  }
}
